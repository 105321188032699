import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import counter from './reducers/counterReducer'
import order from './reducers/orderReducer'
import menu from './reducers/menuReducer'
import tag from './reducers/tagReducer'
import modal from './reducers/modalReducer'
import scroll from './reducers/scrollReducer'
import { persistReducer, persistStore } from 'redux-persist'
import storage from './sync_storage'
import { composeWithDevTools } from 'redux-devtools-extension'

// If you don't bother about the error redux-persist failed to create sync storage. falling back to noop storage...uncomment the next line and comment out the previous import. See more on - https://github.com/vercel/next.js/discussions/15687
// const storage = require('redux-persist/lib/storage').default;

const combinedReducer = combineReducers({
    counter,
    order,
    menu,
    tag,
    modal,
    scroll
})

// TODO: change back !== production
const bindMiddleware = () => {
    const middleware = process.env.NEXT_PUBLIC_APP_ENV !== 'production' ? [logger, thunkMiddleware] : [thunkMiddleware]

    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
}

const makeStore = () => {
    let store
    const isServer = typeof window === 'undefined'

    if (isServer) {
        store = createStore(combinedReducer, bindMiddleware())
    } else {
        const hash = process.env.NEXT_PUBLIC_BITBUCKET_COMMIT.slice(0, 3)
        const persistConfig = {
            key: process.env.NEXT_PUBLIC_BITBUCKET_VERSION + '-' + hash,
            version: 9,
            whitelist: ['counter', 'order', 'tag', 'modal', 'scroll'],
            storage
        }

        const persistedReducer = persistReducer(persistConfig, combinedReducer)
        store = createStore(persistedReducer, undefined, bindMiddleware())
        store.__persistor = persistStore(store)
    }
    return store
}
export const wrapper = createWrapper(makeStore)
