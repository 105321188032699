import axios from 'axios'
import { GraphQLClient } from 'graphql-request'
import { checkCookies, getCookie, setCookies } from 'cookies-next'
import { captureException } from '@sentry/nextjs'
import { resetOrderAndCreateNew } from '@/functions/orderControl/orderControl'

const env = process.env.NEXT_PUBLIC_APP_ENV || 'development'

//DEV
//https://app.stage.speedlo.cloud/graphql

//PROD
//https://c2e-p1.deep-vision.cloud/graphql

export const clientEndpoint =
    env === 'development' ? 'https://app.stage.speedlo.cloud/graphql' : 'https://c2e-p1.deep-vision.cloud/graphql'

// eslint-disable-next-line no-unused-vars
const PROD_TOKEN =
    'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo4MDQsImF1dGhlbnRpY2F0aW9uX3Rva2VuIjoiN3RrQTZrZjZMSFkwaTBBSkxxU25QZ3R0IiwiaWF0IjoyMTU3NDA5OTUsImp0aSI6IjE5YjI4NWZkYTY3NTQyZmE4ZWU4ZjA4N2NjYTA4Yjg1In0.v0h53HVm_AWb5zSo72wXmSHC4TihuDVoq0bEbgfvCng'

export const graphQLClient = async dispatch => {
    let token = null
    if (checkCookies('token')) token = getCookie('token')

    if (token === null || token === undefined) {
        console.error('Token expired')
        if (dispatch) resetOrderAndCreateNew(dispatch)

        let speedloUserToken = await userToken()

        // let expiration = 36000 // 10h hours expiration for token
        if (!checkCookies('token')) {
            setCookies('token', speedloUserToken, { secure: false })
            // localStorage.setItem('tokenExpiration', moment().add(expiration, 'seconds'))
        }

        return new GraphQLClient(clientEndpoint, {
            headers: {
                Authorization: 'Bearer ' + speedloUserToken,
                'X-User-Language': 'cs'
            }
        })
    } else {
        return new GraphQLClient(clientEndpoint, {
            headers: {
                Authorization: 'Bearer ' + token,
                'X-User-Language': 'cs'
            }
        })
    }
}

// PROD
// https://c2e-p1.deep-vision.cloud/users/token?guest=true

//DEV
// https://app.dev.speedlo.cloud/users/token?guest=true

// PROD
// https://c2e-p1.deep-vision.cloud/users/token?username=rucnik@rucnik.rr&password=YmE5MmZhMjRhODdhNWMyZjhhNTIzZDVj

const userToken = async () => {
    const env = process.env.NEXT_PUBLIC_APP_ENV || 'development'

    const clientEndpoint =
        env === 'development'
            ? 'https://app.stage.speedlo.cloud/users/token?guest=true'
            : 'https://c2e-p1.deep-vision.cloud/users/token?guest=true'

    return axios
        .get(clientEndpoint)
        .then(res => {
            if (res.data != null) return res.data.token
        })
        .catch(err => {
            captureException(err)
            console.error(err)
        })
}
