import { createSelector } from 'reselect'

const orderSelect = state => state.order
const menuSelect = state => state.menu
const tagSelect = state => state.tag
const modalSelect = state => state.modal
const scrollSelect = state => state.scroll

export const branchIdSelector = createSelector(orderSelect, state => {
    return state.orderData && state.orderData.id
})

export const orderDeliveryTypeSelector = createSelector(orderSelect, state => {
    return state.deliveryType
})

export const orderDataZoneIdSelector = createSelector(orderSelect, state => {
    return state.orderData && state.orderData.zoneId
})
export const orderIdSelector = createSelector(orderSelect, state => {
    return state.id
})

export const orderDataSelector = createSelector(orderSelect, state => {
    return state.orderData
})
export const orderTipSelector = createSelector(orderSelect, state => {
    return state.tip
})
export const orderTipSelectedSelector = createSelector(orderSelect, state => {
    return state.tipSelected
})
export const backupOrderDataSelector = createSelector(orderSelect, state => {
    return state.backupOrderData
})

export const branchClosedOrderOnLaterTimeSelector = createSelector(orderSelect, state => {
    return state.branchClosedOrderOnLaterTime
})

export const orderDataFormattedAddressSelector = createSelector(orderSelect, state => {
    return state.orderData && state.orderData.formattedAddress
})

export const orderUpdateNumberSelector = createSelector(orderSelect, state => {
    return state.updateNumber
})
export const tokenExpireSelector = createSelector(orderSelect, state => {
    return state.tokenExpiration
})

export const currentProductSelector = createSelector(orderSelect, state => {
    return state.currentProductSelected
})

export const modalSelector = createSelector(modalSelect, state => {
    return {
        type: state.modal.type,
        productId: state.modal.productId
    }
})
export const currentTagSelector = createSelector(tagSelect, state => {
    return state.currentTag
})

export const menuSelector = createSelector(menuSelect, state => {
    return state.menu
})

export const addonSelector = createSelector(modalSelect, state => {
    return state.addons
})

export const modalProductInfoSelector = createSelector(modalSelect, state => {
    return state.productInfo
})

export const scrollSelector = createSelector(scrollSelect, state => {
    return state.scrollToElement
})
