import { graphQLClient } from '@/graphql/client'
import { promoCategoriesQuery } from '@/graphql/query/promoCategoriesQuery'
import { decode } from 'js-base64'
import { promoSingleCategoriesQuery } from '@/graphql/query/promoSingleCategoriesQuery'
import { showError, showInfo } from '@/functions/ui/toastify'
import { captureException } from '@sentry/nextjs'
import { resetOrderAndCreateNew } from '@/functions/orderControl/orderControl'

// eslint-disable-next-line no-unused-vars
export const callGraphQlClient = async (query, variables, dispatch) => {
    const client = await graphQLClient(dispatch)
    return await client.request(query, variables)
}

export const parseVariablesFromURL = router => {
    const orderId = router.query.orderId
    const speedloUserToken = router.query.userToken

    return {
        orderId: orderId,
        userToken: speedloUserToken
    }
}

export const callGraphQlClientFromURL = async (query, variables, router) => {
    const client = await graphQLClient()

    let urlVariables = parseVariablesFromURL(router)

    client.setHeaders({
        Authorization: 'Bearer ' + decode(urlVariables.userToken),
        'X-User-Language': 'cs'
    })

    return await client.request(query, variables)
}

export async function loadBranchPromoCategories(companyBranchId, dispatch) {
    let variables = {
        branchId: companyBranchId
    }
    let promoCategories = await callGraphQlClient(promoSingleCategoriesQuery, variables, dispatch)
    return Promise.all([promoCategories.header, promoCategories.footer])
}

export async function loadSideDishPromoCategories(companyBranchId, dispatch) {
    let variables = {
        branchId: companyBranchId,
        promoTags: ['PROMO_SIDE_DISH']
    }
    return callGraphQlClient(promoCategoriesQuery, variables, dispatch)
}

export const getErrors = error => {
    return error.errors.map(err => err.message)
}

export const getUserErrors = error => {
    return error.errors.filter(shouldShowToUser).map(err => err.message)
}

export const shouldShowToUser = error => {
    return Boolean(error.extensions && error.extensions.showToUser)
}

export function resolveError(errors) {
    const noAccess = errors.filter(
        item => item.includes('Uživatel nemůže provést akci') || item.includes('Nepovolený přístup ')
    )
    if (noAccess.length === 0) {
        showError('Nastala neočekávaná chyba')
    }
}

export function relationFinish(router, dispatch, resetExpiredTokenLock, delay = 2500) {
    showInfo('Vypršela platnost relace, budete přesměrování na hlavní stránku')
    setTimeout(() => {
        router
            .replace('/')
            .then(() => {
                resetExpiredTokenLock()
                resetOrderAndCreateNew(dispatch)
                console.debug('Transfered user to homepage - no valid token')
            })
            .catch(err => {
                captureException(err)
            })
    }, delay)
}
