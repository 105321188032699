import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import CookieConsent from '@/ui/components/cookies/CookieConsent'
import { getOrderQuery } from '@/graphql/query/getOrderQuery'
import {orderDataSelector, orderIdSelector, orderUpdateNumberSelector} from '@/selectors/selectors'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'
import { removeCookies } from 'cookies-next'
import { graphQLClient } from '@/graphql/client'
import { resetOrderAndCreateNew, setupPaymentFinalScreen } from '@/functions/orderControl/orderControl'

const AppContext = createContext(null)

export function AppWrapper({ children }) {
    let dispatch = useDispatch()
    const router = useRouter()

    const [sharedData, setSharedData] = useState(null)
    const orderId = useSelector(orderIdSelector)
    const updatedNumber = useSelector(orderUpdateNumberSelector)
    const orderData = useSelector(orderDataSelector)
    const loadedBranchName = useCallback(() => {
        if (orderData.url) return orderData.url
    }, [orderData && orderData.url])
    // const tokenExpiration = useSelector(tokenExpireSelector)
    // const [expiredTokenLocked, setExpiredTokenLocked] = useState(false)

    /*  useEffect(() => {
        if (!Cookies.get('token')) {
            if (!expiredTokenLocked) {
                setExpiredTokenLocked(true)
                graphQLClient(dispatch).then(() => {
                    console.info('generated new token')

                    resetOrderAndCreateNew(dispatch)
                    if (router.pathname !== '/') {
                        router.replace('/').then(() => {
                            showInfo('Vypršela platnost relace, přesměrovali jsme vás na domovskou stránku')
                        })
                    }
                    setExpiredTokenLocked(false)
                })
            }
        }
    }, [tokenExpiration])*/

    useEffect(() => {
        if (!localStorage.getItem('resetTokenExp')) {
            localStorage.setItem('resetTokenExp', 'true')
            removeCookies('token')
            graphQLClient().then(() => {
                let branch = null
                if (router.query.branch) branch = router.query.branch
                if (branch !== null) router.replace('/' + branch)
                else router.replace('/')
                resetOrderAndCreateNew(dispatch)
            })
        }
    }, [router.query])

    useEffect(() => {
        if (orderId !== null) {
            const variables = {
                orderId: parseInt(orderId)
            }
            callGraphQlClient(getOrderQuery, variables, dispatch)
                .then(res => {
                    setSharedData(res)
                })
                .catch(err => {
                    captureException(err)
                })
        } else setSharedData(null)
    }, [updatedNumber, orderId])

    /*    useEffect(() => {
        if (sharedData !== null) {
            if (!sharedData.order.isLocked) {
                setTimeout(() => {
                    if (sharedData !== null && router.pathname !== '/')
                        router.replace('/').catch(err => {
                            captureException(err)
                        })
                }, 3000)
            }
        }
    }, [updatedNumber, sharedData, router.query, router, orderId])*/

    useEffect(() => {
        if (sharedData !== null) {
            if (sharedData.order.isLocked) {
                if (!router.pathname.includes('verifikace') && !router.pathname.includes('dekujeme')) {
                    const verificationURL = setupPaymentFinalScreen(router, orderId, true, loadedBranchName())
                    router.push(verificationURL).catch(err => {
                        captureException(err)
                    })
                }
            }
        }
    }, [sharedData, router.query, orderId, router])

    return (
        <AppContext.Provider value={sharedData}>
            <CookieConsent />
            {children}
        </AppContext.Provider>
    )
}

export function useAppContext() {
    return useContext(AppContext)
}
